import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import packageJson from '../../../../../../package.json';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  signInUrl = `${environment.authUrl}/oauth2/v2.0/authorize`;
  legalsDocumentsLink = {
    es: 'https://www.o-i.com/es/legal/documentos-legales-america/',
    en: 'https://www.o-i.com/legal/legal-documents-americas/',
    pt: 'https://www.o-i.com/legal/legal-documents-americas/',
  };
  version!: string;

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
  ) {}

  get lang() {
    return this.translate.currentLang;
  }

  async ngOnInit() {
    const codeChallenge = await this.authService.getCodeChallenge();

    const url = new URL(this.signInUrl);

    url.searchParams.append('p', environment.APP_REGISTRATION_FLOW);
    url.searchParams.append('client_id', environment.clientId);
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('redirect_uri', environment.redirectUri);
    url.searchParams.append(
      'scope',
      `openid ${environment.scopeUrl}/${environment.AUD_ID}/access_as_user`,
    );
    url.searchParams.append('prompt', 'login');
    url.searchParams.append('code_challenge_method', 'S256');
    url.searchParams.append('code_challenge', codeChallenge);
    url.searchParams.append('nonce', 'defaultNonce');

    this.signInUrl = url.toString();

    this.version = packageJson.version;
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  getLegalDocumentLink(lang: string) {
    return Object(this.legalsDocumentsLink)[lang];
  }
}
