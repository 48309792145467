import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, first } from 'rxjs';

import { SessionModel } from '../../models/session.model';
import { AuthService, AuthType } from '../../services/auth.service';
import { SessionService } from '../../services/session.service';
import { rolId } from '../../models/rols.model';

@Component({
  templateUrl: './verify.component.html',
})
export class VerifyComponent implements OnDestroy {
  // private fields
  private routerSubscr: Subscription;
  private loginSubscr!: Subscription;
  private sessionSubscr!: Subscription;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.routerSubscr = this.route.queryParams.subscribe(params => {
      const code = params['code'];

      if (!code) {
        this.router.navigate(['/auth/login']);
        return;
      }

      this.loginSubscr = this.authService
        .login(code)
        .pipe(first())
        .subscribe((auth: AuthType) => {
          this.initSession(auth);
        });
      this.unsubscribe.push(this.loginSubscr);
    });
    this.unsubscribe.push(this.routerSubscr);
  }

  initSession(auth: AuthType) {
    if (!auth) return;
    this.sessionSubscr = this.sessionService.getSessionData().subscribe(() => {
      const session: SessionModel | undefined =
        this.sessionService.getSessionDataFromLocalStorage();

      if (!session) {
        this.authService.logout();
        return;
      }

      if (
        session.rol.id === rolId.carrierAdmin ||
        session.rol.id === rolId.carrierOperator
      ) {
        this.router.navigate(['/dashboard/shipments/list']);
        return;
      }

      if (
        session.rol.id === rolId.whGuard ||
        session.rol.id === rolId.whOperator
      ) {
        this.authService.logout();
        return;
      }

      this.router.navigate(['/dashboard/outbounds/list']);
    });
    this.unsubscribe.push(this.sessionSubscr);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
