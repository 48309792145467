import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AuthModel } from '../models/auth.model';

const AUTH_URL = `${environment.authUrl}/${environment.APP_REGISTRATION_FLOW}/oauth2/v2.0`;

export type AuthType = AuthModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private authSubscr: Subscription;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private codeLocalStorageToken = environment.VERIFIER_KEY;
  private authLocalStorageToken = environment.AUTHDATA_KEY;
  private sessionLocalStorageToken = environment.SESSION_KEY;

  // public fields
  currentAuth$: Observable<AuthType>;
  currentAuthSubject: BehaviorSubject<AuthType>;
  hours: number = 12;

  get currentAuthValue(): AuthType {
    return this.currentAuthSubject.value;
  }

  set currentAuthValue(auth: AuthType) {
    this.currentAuthSubject.next(auth);
  }

  get currentCodeVerifier(): string {
    return this.getCodeFromLocalStorage();
  }

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    this.currentAuthSubject = new BehaviorSubject<AuthType>(undefined);
    this.currentAuth$ = this.currentAuthSubject.asObservable();
    this.authSubscr = this.loadAuth().subscribe();
    this.unsubscribe.push(this.authSubscr);
  }

  // public methods
  login(code: string): Observable<AuthType> {
    return this.loginRequest(code).pipe(
      map((auth: AuthType) => {
        if (auth) {
          this.setAuthFromLocalStorage(auth);
          this.currentAuthSubject.next(auth);
        } else {
          this.logout();
        }
        return auth;
      }),
      catchError(error => {
        console.error('error', error);
        return of(undefined);
      }),
    );
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem(this.sessionLocalStorageToken);
    this.currentAuthSubject.next(undefined);
    window.location.href = `${AUTH_URL}/logout?post_logout_redirect_uri=${environment.redirectUri}&id_token_hint=${this.currentAuthValue?.accessToken}`;
  }

  refreshToken(token: string): Observable<AuthType> {
    return this.refreshTokenRequest(token).pipe(
      map((auth: AuthType) => {
        if (auth) {
          this.setAuthFromLocalStorage(auth);
          this.currentAuthSubject.next(auth);
        } else {
          this.logout();
        }
        return auth;
      }),
      catchError(error => {
        console.error('error', error);
        return of(undefined);
      }),
    );
  }

  loadAuth(): Observable<AuthType> {
    const auth = this.getAuthFromLocalStorage();

    if (!auth) {
      return of(undefined);
    }

    this.currentAuthSubject.next(auth);

    return of(auth);
  }

  async getCodeChallenge() {
    return await this.encodeS256()
      .then(hash => window.btoa(hash))
      .then(b64 =>
        b64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_'),
      );
  }

  // private methods
  private getNonce(): string {
    let result = '';
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 128; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  private async encodeS256(): Promise<string> {
    const codeVerifier = this.getNonce();
    this.setCodeFromLocalStorage(codeVerifier);

    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);

    const hash = await window.crypto.subtle.digest('SHA-256', data);

    let binary = '';
    const bytes = new Uint8Array(hash);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return binary;
  }

  private loginRequest(code: string): Observable<AuthType> {
    const body = new URLSearchParams();

    body.set('grant_type', 'authorization_code');
    body.set('client_id', environment.clientId);
    body.set('redirect_uri', environment.redirectUri);
    body.set(
      'scope',
      `${environment.scopeUrl}/${environment.AUD_ID}/access_as_user`,
    );
    body.set('code', code);
    body.set('code_verifier', this.currentCodeVerifier);

    return this.http
      .post<AuthType>(`${AUTH_URL}/token`, body.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        responseType: 'json',
      })
      .pipe(
        map((data: AuthType) => {
          localStorage.removeItem(this.codeLocalStorageToken);

          if (!data) {
            return undefined;
          }

          const obj = {
            accessToken: Object(data).access_token,
            tokenType: Object(data).token_type,
            notBefore: Object(data).not_before,
            expiresIn: Object(data).expires_in,
            expiresOn: Object(data).expires_on * 1000,
            resource: Object(data).resource,
            profileInfo: Object(data).profile_info,
            scope: Object(data).scope,
            refreshToken: Object(data).refresh_token,
            logoutOn: Date.now() + this.hours * 60 * 60 * 1000,
          };

          const auth = new AuthModel(obj);
          return auth;
        }),
        catchError(error => {
          console.error('error', error);
          return of(undefined);
        }),
      );
  }

  private refreshTokenRequest(token: string): Observable<AuthType> {
    const body = new URLSearchParams();

    body.set('grant_type', 'refresh_token');
    body.set('client_id', environment.clientId);
    body.set(
      'scope',
      `${environment.scopeUrl}/${environment.AUD_ID}/access_as_user`,
    );
    body.set('refresh_token', token);
    body.set('redirect_uri', environment.redirectUri);

    return this.http
      .post<AuthType>(`${AUTH_URL}/token`, body.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        responseType: 'json',
      })
      .pipe(
        map((data: AuthType) => {
          if (!data) {
            return undefined;
          }

          const obj = {
            accessToken: Object(data).access_token,
            tokenType: Object(data).token_type,
            notBefore: Object(data).not_before,
            expiresIn: Object(data).expires_in,
            expiresOn: Object(data).expires_on * 1000,
            resource: Object(data).resource,
            profileInfo: Object(data).profile_info,
            scope: Object(data).scope,
            refreshToken: Object(data).refresh_token,
            logoutOn:
              this.currentAuthValue?.logoutOn ||
              Date.now() + this.hours * 60 * 60 * 1000,
          };

          const auth = new AuthModel(obj);
          return auth;
        }),
        catchError(error => {
          console.error('error', error);
          return of(undefined);
        }),
      );
  }

  private setCodeFromLocalStorage(codeVerifier: string): boolean {
    if (codeVerifier) {
      localStorage.setItem(this.codeLocalStorageToken, codeVerifier);
      return true;
    }
    return false;
  }

  private getCodeFromLocalStorage(): string {
    try {
      const value = localStorage.getItem(this.codeLocalStorageToken);
      return value ?? '';
    } catch (error) {
      console.error('error', error);
      return '';
    }
  }

  private setAuthFromLocalStorage(auth: AuthType): boolean {
    if (auth) {
      localStorage.setItem(
        this.authLocalStorageToken,
        window.btoa(JSON.stringify(auth)),
      );
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthType {
    try {
      const value = localStorage.getItem(this.authLocalStorageToken);
      return value ? JSON.parse(window.atob(value)) : undefined;
    } catch (error) {
      console.error('error', error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
