<div class="login-container">
  <div class="login-img">
    <img src="assets/images/logo.svg" alt="login" />
  </div>
  <div class="login-form">
    <div class="login-laguange-tools">
      <button class="login-laguange-btn" (click)="useLanguage('en')">EN</button>
      <button class="login-laguange-btn" (click)="useLanguage('es')">ES</button>
      <button class="login-laguange-btn" (click)="useLanguage('pt')">PT</button>
    </div>
    <h1 class="title">{{ 'login.title' | translate }}</h1>
    <a
      [href]="signInUrl"
      rel="noopener noreferrer"
      class="login-btn p-4 text-center">
      {{ 'login.submit' | translate }}
    </a>
    <div>
      <p>
        {{ 'login.agree' | translate }}
        <a class="text-blue-400" [href]="getLegalDocumentLink(lang)">{{
          'login.conditionsOfUse' | translate
        }}</a>
        {{ 'login.and' | translate }}
        <a class="text-blue-400" [href]="getLegalDocumentLink(lang)">{{
          'login.privacyNotice' | translate
        }}</a>
      </p>
    </div>
    <div class="mt-2 text-center">
      <span class="font-bold">v{{ version }}</span>
    </div>
  </div>
</div>
<footer class="footer">
  <div class="footer-content">
    <span class="footer-text"
      >2023 O-I Glass. All rights reserved. Privacy | Legal Notice | Contact &
      Location</span
    >
    {{ lang }}
  </div>
</footer>
