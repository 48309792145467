export class AuthModel {
  accessToken: string;
  tokenType: string;
  notBefore: number;
  expiresIn: number;
  expiresOn: number;
  resource: string;
  profileInfo: string;
  scope: string;
  refreshToken: string;
  logoutOn: number;

  constructor(_data: unknown) {
    const data = _data as AuthModel;
    this.accessToken = data.accessToken;
    this.tokenType = data.tokenType;
    this.notBefore = data.notBefore;
    this.expiresIn = data.expiresIn;
    this.expiresOn = data.expiresOn;
    this.resource = data.resource;
    this.profileInfo = data.profileInfo;
    this.scope = data.scope;
    this.refreshToken = data.refreshToken;
    this.logoutOn = data.logoutOn;
  }
}
